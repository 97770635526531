<template>
  <div style="height: calc(100vh - 88px)" class="grey lighten-2">
    <v-form ref="form" v-model="valid">
      <v-container fluid>
        <v-row align="center">
          <v-col cols="12">
            <v-card rounded="0">
              <v-card-text>
                <v-container fluid class="my-0 py-0">
                  <v-row align="center">
                    <v-col cols="1">
                      <v-icon size="50" @click="goToStuffing">mdi-arrow-left</v-icon>
                    </v-col>
                    <v-col cols="5" class="text-left">
                      <div class="text-h5 text-uppercase font-weight-regular">{{ tLabel("Ordine di stuffing") }}</div>
                    </v-col>
                    <v-col cols="6" class="text-right">
                      <v-btn outlined color="success" @click="save(false)" v-if="orderId === null" :disabled="addOperationMode">
                        <v-icon class="pr-2">mdi-content-save</v-icon>{{ tLabel("Salva e nuovo") }}
                      </v-btn>
                      &nbsp;
                      <v-btn color="success" @click="save(true)" :disabled="addOperationMode"> <v-icon class="pr-2">mdi-content-save</v-icon>{{ tLabel("Salva") }} </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row align="center" no-gutters>
          <v-col cols="12" class="fill-height">
            <v-container fluid class="fill-height mx-0 px-0">
              <v-row no-gutters class="fill-height">
                <v-col cols="12">
                  <v-tabs v-model="tab" left background-color="panelheader">
                    <v-tab class="text-h6" :disabled="addOperationMode">{{ tLabel("Order") }}</v-tab>
                    <v-tab class="text-h6">{{ tLabel("Operations") }}</v-tab>
                  </v-tabs>

                  <v-tabs-items v-model="tab" touchless class="fill-height scroller" style="height: calc(100vh - 254px)">
                    <v-tab-item>
                      <v-container fluid>
                        <v-row align="center" v-if="!addOperationMode">
                          <v-col cols="2">
                            <v-text-field v-model="order.reference" :label="tLabelRequired('Riferimento pratica')" :rules="[rules.required]" @input="(val) => (order.reference = val ? val.toUpperCase() : null)" />
                          </v-col>
                          <v-col cols="2">
                            <v-text-field v-model="order.extCode" :label="tLabel('Codice esterno')" @input="(val) => (order.extCode = val ? val.toUpperCase() : null)"/>
                          </v-col>
                          <v-col cols="3">
                            <v-autocomplete :label="tLabel('Cliente')" v-model="order.companyId" :items="allCompanies" item-value="id" item-text="code" />
                          </v-col>
                        </v-row>
                        <v-row align="center" v-if="!addOperationMode" class="mb-0 pb-0">
                          <v-col cols="12" class="text-right mb-0 pb-0">
                            <v-btn outlined color="primary" @click="retrieveNotes(order)"><v-icon class="mr-2">mdi-refresh</v-icon> {{ tLabel("Aggiorna note") }}</v-btn>
                          </v-col>
                        </v-row>
                        <v-row align="center" v-if="!addOperationMode" class="mt-0 pt-0">
                          <v-col cols="6" class="mt-1 pt-1">
                            <v-textarea background-color="grey lighten-5" v-model="order.details" :label="tLabel('Dettagli ordine')" rows="17" class="my-0 py-0" />
                          </v-col>
                          <v-col cols="6" class="mt-1 pt-1">
                            <v-textarea background-color="grey lighten-5" v-model="order.remarks" :label="tLabel('Note')" rows="17" class="my-0 py-0" />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-tab-item>

                    <v-tab-item>
                      <v-container fluid class="grey lighten-5">
                        <v-row align="center">
                          <v-col cols="12" class="text-right" v-if="!addOperationMode">
                            <v-btn color="primary" @click="prepareAddOperation"><v-icon class="pr-2">mdi-plus</v-icon>{{ tLabel("Aggiungi") }}</v-btn>
                          </v-col>
                          <v-col cols="12" class="text-right" v-else>
                            <v-btn outlined @click="addOperationMode = false" class="mr-2"><v-icon class="pr-2">mdi-cancel</v-icon>{{ tLabel("Annulla") }}</v-btn>
                            <v-btn color="success" @click="addOperation" :disabled="confirmDisabled"><v-icon class="pr-2">mdi-check</v-icon>{{ tLabel("Conferma") }}</v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                      <v-container fluid v-if="!addOperationMode">
                        <v-row align="center" v-if="order.operations && order.operations.length > 0" :key="operationsKey">
                          <v-col cols="12">
                            <div v-for="op in order.operations" :key="op.id">
                              <StuffingOperationCard
                                :operation="op"
                                :removeEnabled="!op.unit || op.unit === null || op.unit.locked === false"
                                @operationRemoving="removeOperation"
                              />
                            </div>
                          </v-col>
                        </v-row>
                        <v-row align="center" v-else>
                          <v-col cols="12" class="text-h6 font-weight-light">
                            {{ tLabel("Nessuna operazione di stuffing per l'ordine.") }}
                          </v-col>
                        </v-row>
                      </v-container>
                      <v-container fluid v-else>
                        <v-row align="start">
                          <v-col cols="6">
                            <v-card rounded="0" flat>
                              <v-card-title class="text-body-1 text-uppercase font-weight-light pl-0">{{ tLabel("Goods") }}</v-card-title>
                              <v-divider />
                              <v-card-text>
                                <GoodsSelectionPerOperationPanel :goods="goodsAvailable" :goodsToAdd="goodsToAdd" />
                              </v-card-text>
                            </v-card>
                          </v-col>
                          <v-col cols="6">
                            <v-card rounded="0" flat>
                              <v-card-title class="text-body-1 text-uppercase font-weight-light pl-0">{{ tLabel("Unit") }}</v-card-title>
                              <v-divider />
                              <v-card-text>
                                <UnitSelectionPerOperationPanel :units="unitsAvailable" :unitsToAdd="unitsToAdd" singleSelection />
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-tab-item>
                  </v-tabs-items>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import FormatMixins from "../../../mixins/FormatsMixins";
import ToastMixin from "../../../mixins/ToastMixin";
import StuffingOperationCard from "@/components/yard/StuffingOperationCard";
import UnitSelectionPerOperationPanel from "@/components/yard/UnitSelectionPerOperationPanel";
import GoodsSelectionPerOperationPanel from "@/components/yard/GoodsSelectionPerOperationPanel";

export default {
  name: "ManageStuffingOrder",
  data() {
    return {
      order: {
        type: "STUFFING",
      },
      operationsKey: 0,
      unitSearchFilter: null,
      goodsSearchFilter: null,
      unitsNotExited: [],
      goodsNotExitedAndNotInOrders: [],
      unitsAvailable: [],
      goodsAvailable: [],
      goodsRemoved: [],
      addOperationMode: false,
      unitsToAdd: [],
      goodsToAdd: [],
      valid: true,
      allCompanies: [],
      tab: null,
    };
  },
  props: {
    orderId: {
      type: Number,
      required: false,
      default: null,
    },
    attrs: {
      type: Object,
      default: null,
    },
  },
  mixins: [FormatMixins, ToastMixin],
  components: { StuffingOperationCard, UnitSelectionPerOperationPanel, GoodsSelectionPerOperationPanel },
  async mounted() {
    this.allCompanies = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "companies/all");

    if (this.orderId && this.orderId !== null) {
      this.order = await this.$api.yardData.getOrder("stuffing", this.orderId);
    }
  },
  computed: {
    confirmDisabled() {
      return this.unitsToAdd.length === 0 && this.goodsToAdd.length === 0;
    },
  },
  methods: {
    goToStuffing() {
      if (this.attrs) {
        this.attrs.orderFromId = this.orderId;
      }
      this.$router.push({
        name: "Stuffing",
        params: {
          attrs: this.attrs,
        },
      });
    },
    async prepareAddOperation() {
      this.addOperationMode = true;
      this.unitSearchFilter = null;
      this.goodsSearchFilter = null;
      this.unitsToAdd = [];
      this.goodsToAdd = [];

      this.unitsNotExited = await this.$api.yardData.getUnitsNotExited("stuffing");
      this.goodsNotExitedAndNotInOrders = await this.$api.yardData.getGoodsNotExitedAndNotInOrders();

      if (this.order.operations && this.order.operations !== null && this.order.operations.length > 0) {
        var unitVisitIds = [];
        var goodsVisitIds = [];
        this.order.operations.forEach((op) => {
          if (op.unit && op.unit !== null) {
            unitVisitIds.push(op.unit.visitId);
          }
          if (op.goods && op.goods !== null && op.goods.length > 0) {
            op.goods.forEach((goo) => {
              goodsVisitIds.push(goo.visitId);
            });
          }
        });
        console.log("visit ids unit/goods", unitVisitIds, goodsVisitIds);
        if (unitVisitIds.length > 0) {
          this.unitsAvailable = this.unitsNotExited.filter((unit) => !unitVisitIds.includes(unit.visitId));
        } else {
          this.unitsAvailable = JSON.parse(JSON.stringify(this.unitsNotExited));
        }
        if (goodsVisitIds.length > 0) {
          this.goodsAvailable = this.goodsNotExitedAndNotInOrders.filter((goo) => !goodsVisitIds.includes(goo.visitId));
        } else {
          this.goodsAvailable = JSON.parse(JSON.stringify(this.goodsNotExitedAndNotInOrders));
        }
      } else {
        this.unitsAvailable = JSON.parse(JSON.stringify(this.unitsNotExited));
        this.goodsAvailable = JSON.parse(JSON.stringify(this.goodsNotExitedAndNotInOrders));
      }
      if (this.orderId && this.orderId !== null) {
        this.goodsRemoved.forEach((gr) => {
          if (!this.goodsAvailable.some((ga) => gr.visitId === ga.visitId)) {
            this.goodsAvailable.push(gr);
          }
        });
      }
    },
    addOperation() {
      if (this.unitsToAdd.length > 0 || this.goodsToAdd.length > 0) {
        if (!this.order.operations || this.order.operations === null) {
          this.order.operations = new Array();
        }
        this.order.operations.push({
          id: this.order.operations.length + 1,
          unit: this.unitsToAdd.length > 0 ? JSON.parse(JSON.stringify(this.unitsToAdd[0])) : null,
          goods: JSON.parse(JSON.stringify(this.goodsToAdd)),
        });
        if (this.orderId && this.orderId !== null) {
          this.goodsRemoved = this.goodsRemoved.filter((gr) => !this.goodsToAdd.some((ga) => gr.visitId === ga.visitId));
        }
      }
      this.addOperationMode = false;
    },
    removeOperation(operation) {
      if (this.orderId && this.orderId !== null && operation.goods && operation.goods !== null && operation.goods.length > 0) {
        operation.goods.forEach((g) => {
          this.goodsRemoved.push(JSON.parse(JSON.stringify(g)));
        });
      }
      var index = this.order.operations.indexOf(operation);
      if (index > -1) {
        this.order.operations.splice(index, 1);
      }
      this.order.operations = JSON.parse(JSON.stringify(this.order.operations));
      this.operationsKey++;
    },
    async save(exit) {
      const valid = this.$refs.form.validate();
      if (!valid) {
        return;
      }
      try {
        console.log("save", exit, this.order, JSON.stringify(this.order));
        var res = await this.$api.yardData.createUpdateOrder(this.order);
        console.log("save res", res); // TODO valutare res?
        this.showSuccess("Ordine " + this.order.reference + "/" + this.order.extCode + " " + (this.orderId === null ? "creato" : "modificato") + " con successo.");
        if (exit && exit === true) {
          this.goToStuffing();
        } else {
          this.order = { type: "STUFFING" };
          this.$refs.form.resetValidation();
        }
      } catch (ex) {
        console.error("Errore " + (this.orderId === null ? "creazione" : "modifica") + " ordine.", ex);
        let message = ex.detailMessage ? ex.detailMessage : ex;
        this.showError("Errore " + (this.orderId === null ? "creazione" : "modifica") + " ordine: " + message);
      }
    },
    async retrieveNotes(order){
      order.remarks=await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "orders/notes/"+order.extCode);
    },
  },
};
</script>

<style scoped>
.scroller {
  overflow-y: auto;
  scrollbar-width: thin;
}

.scroller::-webkit-scrollbar {
  width: 6px;
}

.scroller::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scroller::-webkit-scrollbar-thumb {
  background: #888;
}

.scroller::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.pointer {
  cursor: pointer;
}
</style>
